.gauge_container {
  height: 100%;
  max-width: 250px;
  margin: 0 auto;
  position: relative;
}

.gauge_content_container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.gauge_content_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.gauge_content {
}

.gauge_svg {
  width: 100%;
  height: 100%;
  position: relative;
}
