.counter {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 120px;
  height: 44px;
  margin: 0.25rem;
  background-color: #fff;
  border-radius: 4px;
}

.button {
  padding-top: 2px;
  background-color: #fff;
  border: none;
  cursor: pointer;
}

.value {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  padding: 6px 0;
  border: 1px solid #ccc;
  border-radius: 8px;
}
