@keyframes jump {
  from {
    transform: scale(0.01);
  }
  to {
    transform: scale(1);
  }
}

.panel {
  position: relative;
  box-sizing: border-box;
  margin-bottom: 1.5rem;
  padding: 2rem;
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 0.5rem;

  &.selectable {
    &:hover {
      border: 1px solid #7db8e5;
    }
  }

  &.selectedPanel {
    border: 1px solid #7db8e5;
    background: #f2f8fc;
  }

  &.primaryPanel {
    background: #f2f8fc;
    border: 1px solid #7db8e5;
  }

  &.hoverPanel {
    cursor: pointer;
  }

  .panelBody {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }

  .panelContent {
    flex-grow: 1;
  }
  .panelIcon {
    margin-right: 1.5rem;
    flex: 0 0 40px;
  }

  .panelCompleted {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    animation: jump 0.2s forwards;
  }

  .alert {
    display: flex;
    align-items: center;
    padding: 1.25rem;
    gap: 1.25rem;

    &.alertDanger {
      box-sizing: border-box;
      background: #fff4f7;
      border: 1px solid #ed0037;
      border-radius: 0.5rem;
    }

    &.alertSuccess {
      box-sizing: border-box;
      background: #e2faec;
      border: 1px solid #43b929;
      border-radius: 0.5rem;
    }
  }
}
